.main-wrapper {
  display: none;
}

.user-name {
  font-family: 'Plus Jakarta Sans', sans-serif;
  color: #777b81;
  font-weight: normal;
}

.main-wrap-bg {
  background-image: url('../../assets/LandingPage.png');
  background-repeat: no-repeat;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  height: 90vh;
  width: 100vw;
  cursor: pointer;
}

.user-name span{
  text-transform: capitalize;
  margin-left: 10px;
}

.MuiPickerStaticWrapper-root {
  border-radius: 30px;
  width: 50px;
}
.calender-ui {
  display: flex;
  justify-content: center;
  margin-bottom: 1em;
}
.main-wrapper {
  position: absolute;
  right: 2%;
}

.card-titles{
  font-family: 'Plus Jakarta Sans', sans-serif;
  font-size: 1em;
}

.card-subtitles{
  font-family: 'Plus Jakarta Sans', sans-serif;
  font-size: .8em;
  margin-top: 1em;
  font-style: italic;
}

.link-wrap-two {
  font-family: 'Plus Jakarta Sans', sans-serif;
    color: #FFFFFF;
    width: 100%;
    display: flex;
    justify-content: center;
    cursor: pointer;
}

.check-wrap span{
padding-left: 0;
font-family: 'Plus Jakarta Sans', sans-serif;

}

.new-ui-links{
position: absolute;
top: 85%;
right: 5%;
cursor: pointer;
}

.first-circle {
position: relative;

  display: flex;
  /* justify-content: space-around; */
  align-items: center;
  width:80px;
  height: 40px;
  border-radius: 34px;
  
  background-color: #FFFFFF;
  box-shadow: 0px 0px 16px #BFBFBF29;
}

.one {
  position: absolute;
  left: 5px;
  display: flex;
  align-items: center;
}

.first-circle img{
  width: 30px;
}

.two{
  
  background-color: #FFFFFF;
  box-shadow: 0px 0px 16px #BFBFBF29;
 position: absolute;
 right: 0px;
height: 100px;
transform: translateY(-20px);
border-radius: 34px;
width: 40px;
height: 80px;
display: flex;
justify-content: flex-end;
align-items: center;
transition: 0.2s;
flex-direction: column;
overflow: hidden;
}

.two img {
  margin: 5px 0;
}

/* .two:hover{
transform: translateY(-105px);
height: 250px;

} */

@media (max-width: 576px) {
.main-wrap-bg {
  /* padding: 5em !important; */
  background-image: url('../../assets/LandingPageMobile.png');
  width: 100vw;
}
}